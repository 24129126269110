import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// import @mui
import { styled } from '@mui/material/styles';
import { TextField, Grid, Typography, Card, CardContent, Button, InputLabel, Box, Link } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Icon } from '@iconify/react';

import { AppCurrentVisits } from 'src/sections/@dashboard/app';
import Auth from 'src/module/Auth';
import subscription from 'src/module/subscription';
import axios from 'axios';
import UpdateProfileDialog from 'src/components/update-profile/updateProfile';
import Header from '../layouts/dashboard/header/index';

export default function BillingPage() {
  const [subscriptionDetails, setSubscriptionDetails] = React.useState({});
  const [plan, setPlan] = React.useState({});
  const [portalUrl, setPortalUrl] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const navigate = useNavigate();

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<Icon icon="mdi:arrow-forward-circle" sx={{ fontSize: '0.9rem' }} />} {...props} />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  React.useEffect(() => {
    fetchPortalUrl();
    getSubscriptionDetails();
  }, []);
  const plans = [
    { name: 'Intermediate Yearly', id: 'price_1OBwBwSHyeWGBVACIQdK8lFK', keyword: '500' },
    { name: 'Starter', id: 'price_1OBwAdSHyeWGBVACWSbv8wtj', keyword: '250' },
    { name: 'Starter Yearly', id: 'price_1OBwB3SHyeWGBVACnrpFzBOE', keyword: '250' },
    { name: 'Intermediate', id: 'price_1OBwBgSHyeWGBVAChmQKZ0C0', keyword: '500' },
    { name: 'Premium', id: 'price_1OBwCZSHyeWGBVACkY5Xy6KR', keyword: '1000' },
    { name: 'Premium Yearly', id: 'price_1OBwD5SHyeWGBVACqQF7Rlr0', keyword: '1000' },
  ];
  const freePlan = { name: 'Free', id: '813e1f47-8e4f-4a21-a43f-2ecd05e1290a', keyword: '100' };
  const getSubscriptionDetails = () => {
    const freeID = 'd0e6bcc1-b05e-406b-9517-6da2dc7d05a3';
    const freeID1 = 'fd383621-0f3f-4511-8686-695993e3698d';
    if (Auth?.getUserLoginId() == freeID || Auth?.getUserLoginId() == freeID1) {
      const plan = plans.filter((item) => item.id == 'price_1OBwAdSHyeWGBVACWSbv8wtj');
      setPlan(plan[0]);
      return;
    }
    subscription.getSubscriptionDetails(Auth?.getUserLoginId(), (response) => {
      if (response.status == 'success' && response.data !== '') {
        const plan = plans.filter((item) => item.id == response.data.planId);
        setPlan(plan[0]);
        setSubscriptionDetails(response.data);
      } else if (Auth?.getIsFree()) {
        setPlan(freePlan);
      }
    });
  };

  const fetchPortalUrl = async () => {
    const customerId = await Auth.getCustomerId();
    try {
      const response = await axios.post(
        'https://api.stripe.com/v1/billing_portal/sessions',
        // 'customer=cus_NwLZhVq1AeME2S',
        `customer=${customerId}`,
        {
          headers: {
            // Authorization: `Bearer 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`,
            Authorization: ` Bearer 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`,

            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      const portalUrl = response.data.url;
      setPortalUrl(portalUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePortalClick = () => {
    if (portalUrl) {
      window.open(portalUrl, '_blank');
    }
    if (plan?.name == 'Free') {
      navigate('/subscription');
    }
  };

  const handleUpdateClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  return (
    <Grid container>
      <Header />

      <Grid m={2} display={'flex'}>
        <Grid item xs={12} md={7} marginTop={'90px'}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                Subscription
              </Typography>
              <Accordion>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Subscription Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container gap={1}>
                    <Grid item md={5}>
                      <Box>
                        <InputLabel htmlFor="plan-name">Plan Name</InputLabel>
                        <TextField fullWidth id="plan-name" disabled value={plan?.name} />
                      </Box>
                    </Grid>
                    <Grid item md={5}>
                      <Box>
                        <InputLabel htmlFor="renewal">Renewal Date</InputLabel>
                        <TextField
                          fullWidth
                          id="renewal"
                          disabled
                          value={subscriptionDetails?.subscriptionRenewalDate}
                        />
                      </Box>
                    </Grid>

                    <Grid item md={5}>
                      <Box>
                        <InputLabel htmlFor="keywords">Total Keywords</InputLabel>
                        <TextField fullWidth id="keywords" disabled value={plan?.keyword} />
                      </Box>
                    </Grid>

                    <Grid item md={5}>
                      <Box mt={4}>
                        <Button variant="contained" onClick={handlePortalClick}>
                          {plan?.name == 'Free' ? 'Upgrade' : '  Update Subscription'}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
          <Grid item xs={12} marginTop={'20px'}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  Payment Card
                </Typography>
                <Accordion disabled={plan?.name == 'Free' ? true : false}>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Typography> {plan?.name == 'Free' ? 'N/A For free account' : 'Payment Card Details'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container gap={1}>
                      <Grid item md={5}>
                        <Box>
                          <InputLabel htmlFor="card">Card</InputLabel>
                          <TextField fullWidth id="card" disabled value={subscriptionDetails?.card} />
                        </Box>
                      </Grid>
                      <Grid item md={5}>
                        <Box>
                          <InputLabel htmlFor="last">Last-4</InputLabel>
                          <TextField fullWidth id="last" disabled value={subscriptionDetails?.lastDigits} />
                        </Box>
                      </Grid>
                      <Grid item md={5}>
                        <Box>
                          <InputLabel htmlFor="expiry">Expiry</InputLabel>
                          <TextField
                            fullWidth
                            id="expiry"
                            disabled
                            value={`${subscriptionDetails?.expiryMonth}/${subscriptionDetails?.expiryYear}`}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={5}>
                        <Box mt={4}>
                          <Button variant="contained" onClick={handlePortalClick}>
                            Update Card Details
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} marginTop={'20px'}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  Personal
                </Typography>
                <Accordion>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Personal Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container gap={1}>
                      <Grid item md={5}>
                        <Box>
                          <InputLabel htmlFor="name">Name</InputLabel>
                          <TextField fullWidth id="name" disabled value={Auth?.getName()} />
                        </Box>
                      </Grid>
                      <Grid item md={5}>
                        <Box>
                          <InputLabel htmlFor="email">Email</InputLabel>
                          <TextField fullWidth id="email" disabled value={Auth?.getEmail()} />
                        </Box>
                      </Grid>
                      <Grid item md={5}>
                        <Box mt={4}>
                          <Button variant="contained" onClick={handleUpdateClick}>
                            Change Password
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid xs={12} md={4} mx={2} mt={-2}>
          <AppCurrentVisits plan={plan} />
          <Card
            sx={{
              mt: 2.5,
            }}
          >
            <CardContent>
              <Typography variant="h6" component="div">
                Latest Invoice
              </Typography>
              <Accordion disabled={plan?.name == 'Free' ? true : false}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography> {plan?.name == 'Free' ? 'N/A For free account' : 'Invoice Details'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container gap={1}>
                    <Box>
                      <Typography> Invoice Id: {subscriptionDetails?.invoiceId} </Typography>
                      <Link href={subscriptionDetails?.invoiceUrl}> Download Invoice</Link>
                    </Box>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <UpdateProfileDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        name={Auth?.getName()}
        email={Auth?.getEmail()}
      />
    </Grid>
  );
}
