import React from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Divider, Box, Grid, Paper } from '@mui/material';
import { bgBlur } from '../utils/cssStyles';
import Logo from '../components/logo';
import loginImg from '../assets/illustrations/wave.png';

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title> Privacy Policy | Google Rank Check </title>
      </Helmet>
      <StyledRoot>
        <StyledToolbar>
          <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
            <Logo />
          </Box>
        </StyledToolbar>
      </StyledRoot>
      <Grid container marginTop={'100px'}>
        <Grid
          container
          justifyContent="center"
          height={'230px'}
          component={Paper}
          elevation={3}
          m={2}
          p={1}
          style={{
            backgroundImage: `url(${loginImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundColor: '#d1e3e3',
          }}
        >
          <Box
            sx={{
              padding: '50px',
            }}
          >
            <Typography
              variant="h4"
              align="center"
              sx={{
                color: '#FFF',
              }}
            >
              Privacy Policy
            </Typography>
            <small>Last Updated on : 01-07-2023</small>
          </Box>
        </Grid>

        <Grid item sm={12} style={{ margin: '20px' }}>
          <Box item component={Paper} elevation={6} square m={2} p={2}>
            <p>
              Thank you for choosing "Google Rank Check" powered by Marketing Lad as your keyword tracking solution.
              This Privacy Policy outlines how Google Rank Check collects, uses, discloses, and safeguards the personal
              information you provide when using our SaaS tool. Protecting your privacy is of utmost importance to us,
              and we are committed to ensuring the security and confidentiality of your information.
            </p>

            <p>
              Please read this Privacy Policy carefully to understand our practices regarding your personal data. By
              using Google Rank Check, you consent to the collection, use, and disclosure of your information in
              accordance with this policy.
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Information We Collect
            </h2>
            <Divider />

            <h3 style={{ fontWeight: '500' }}>1. Personal Information</h3>
            <p>
              When you create an account with Google Rank Check, we may collect certain personal information, such as
              your name, email address, company name, and payment details. This information is necessary to set up and
              manage your account, provide you with access to our services, and communicate with you regarding your
              account and related updates.
            </p>

            <h3 style={{ fontWeight: '500' }}>2. Usage Data</h3>
            <p>
              Google Rank Check collects usage data, including but not limited to your IP address, browser type, device
              information, pages visited, and interactions within the tool. We use this information to analyze trends,
              improve our services, and enhance the overall user experience.
            </p>

            <h3 style={{ fontWeight: '500' }}>3. Cookies and Similar Technologies</h3>
            <p>
              Google Rank Check may use cookies and similar technologies to collect information about your browsing
              behavior, preferences, and session data. These technologies help us provide a more personalized
              experience, remember your settings, and track the performance of our website and services.
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              How We Use Your Information
            </h2>
            <Divider />

            <h3 style={{ fontWeight: '500' }}>1. Provide and Improve Services</h3>
            <p>
              We use the information collected to provide you with access to Google Rank Check's services, including
              tracking and displaying keyword rankings. We also use this data to improve our services, develop new
              features, and enhance the functionality and performance of our tool.
            </p>

            <h3 style={{ fontWeight: '500' }}>2. Communication</h3>
            <p>
              We may use your contact information to send you important notifications, updates, and service-related
              communications. These communications are essential for the proper functioning of Google Rank Check and to
              keep you informed about changes, new features, and other relevant information.
            </p>

            <h3 style={{ fontWeight: '500' }}>3. Analytics and Research</h3>
            <p>
              We analyze usage data to better understand user behavior, preferences, and trends. This analysis allows us
              to improve our services, optimize user experience, and make informed business decisions.
            </p>

            <h3 style={{ fontWeight: '500' }}>4. Legal Compliance and Protection</h3>
            <p>
              Google Rank Check may disclose your personal information when required by law or in response to valid
              legal requests. We may also disclose information to protect the rights, safety, and security of Google
              Rank Check, its users, and the public.
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Data Security
            </h2>
            <Divider />
            <p>
              Google Rank Check takes appropriate technical and organizational measures to ensure the security and
              confidentiality of your personal information. We utilize industry-standard practices to protect your data
              from unauthorized access, loss, misuse, alteration, or destruction. However, no data transmission or
              storage can be guaranteed to be 100% secure. While we strive to protect your information, we cannot
              warrant the absolute security of any data you transmit to Google Rank Check
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Third-Party Services
            </h2>
            <Divider />
            <p>
              Google Rank Check may use third-party services and tools that collect and process data on our behalf.
              These service providers are bound by confidentiality agreements and are required to implement appropriate
              security measures. We do not sell, rent, or trade your personal information to third parties for marketing
              purposes.
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Data Retention
            </h2>
            <Divider />
            <p>
              Google Rank Check retains your personal information for as long as necessary to fulfill the purposes
              outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We will
              securely delete or anonymize your data once it is no longer needed.
            </p>

            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Your Rights and Choices
            </h2>
            <Divider />
            <p style={{ paddingBottom: '30px' }}>
              You have the right to access, update, correct, and delete your personal information held by Google Rank
              Check. You can manage your account settings and communication preferences by logging into your account.
            </p>
            <h2
              style={{
                color: '#00A3D8',
              }}
            >
              Business Email-Exclusive Free Subscription Plan
            </h2>
            <p style={{ paddingBottom: '30px' }}>
              To access the free subscription, users are required to use business email addresses exclusively. Accounts
              created using free email services like (gmail, yahoo, outlook etc.) will be subject to blocking under the
              free subscription plan. For those interested in registering with free email service extensions, our paid
              plans are available, accommodating such email types.
            </p>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
