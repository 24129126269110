import React, { useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import Auth from 'src/module/Auth';
import DashboardAppPage from './DashboardAppPage';
import { bgBlur } from '../utils/cssStyles';
import './Shared.css';

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
    marginBottom: '100px',
  },
}));

export default function ShareDashboard() {
  const [isCd, setIsCd] = React.useState(true);
  useEffect(() => {}, []);

  return (
    <>
      <Helmet />
      <StyledRoot>
        <StyledToolbar>
          <Box component="img" src="/assets/ml-logo.png" sx={{ width: 100, height: 30, cursor: 'pointer' }} />

          <Box sx={{ flexGrow: 1 }} />
        </StyledToolbar>
      </StyledRoot>
      <Typography
        variant="h5"
        display={'flex'}
        justifyContent={'center'}
        sx={{
          color: '#00A3D8',
          height: '100px',
          alignItems: 'center',
          marginTop: '50px',
        }}
      >
        {/* Keyword Rank Tracker Dashboard */}
        Visualize Your Keyword Progress
      </Typography>

      {isCd && <DashboardAppPage isCd={isCd} />}
    </>
  );
}
