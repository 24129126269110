import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Auth from './module/Auth';

const ProtectedRoutes = ({ element }) => {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        setLoading(true);
        if (Auth?.isSubscribed()) {
          setIsSubscribed(true);
        } else {
          const subscribed = await Auth?.subscribed(Auth.id);
          setIsSubscribed(subscribed);
        }
        setLoading(false);
      } catch (error) {
        console.log('Error checking subscription:', error);
        setLoading(false);
      }
    };

    const checkAuthentication = async () => {
      const authenticated = await Auth.isAuthenticated();
      setIsAuthenticated(authenticated);
    };

    checkSubscription();
    checkAuthentication();
  }, []);

  if (loading) {
    // still fetching subscription status
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (location.pathname === '/dashboard') {
    Auth.getIsCd(); // Set isCd to true for the dashboard route
  }

  if (Auth.getIsCd() === true) {
    // Redirect user to 404 page if isCd is true or undefined
    return <Navigate to="/404" replace />;
  }

  if (isAuthenticated === null || isSubscribed === null) {
    // still fetching subscription or authentication status
    return null;
  }

  if (!isAuthenticated) {
    // user is not authenticated, redirect to login page
    return <Navigate to="/login" replace />;
  }

  if (!isSubscribed) {
    // user is authenticated but not subscribed
    const currentPath = window.location.pathname;

    // allow access to login and subscription routes
    if (currentPath === '/login' || currentPath === '/subscription') {
      return element;
    }

    // redirect to subscription page for all other routes
    return <Navigate to="/subscription" replace />;
  }

  // user is authenticated and subscribed
  const validRoutes = ['/dashboard', '/subscription', '/login', '/404', '/billing', 'client-dashboard'];
  const currentPath = window.location.pathname;
  const isValidRoute = validRoutes.some((route) => currentPath.startsWith(route));

  if (isValidRoute || currentPath === '/') {
    // allow access to valid routes
    return element;
  } else {
    // redirect to 404 Not Found page for all other routes
    return <Navigate to="/404" replace />;
  }
};

export default ProtectedRoutes;
