import React, { useState ,useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, styled, Container, Typography, TextField,InputAdornment } from '@mui/material';
import { useNavigate, useParams,useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// hooks
import Iconify from 'src/components/iconify/Iconify';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import forgot from '../assets/illustrations/3 (3).png';
// module
import Auth from '../module/Auth';



// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 450,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ResetPassword = () => {
  const [resetcode, setResetcode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const location = useLocation();
  const [helperText, setHelperText] = useState('');
  const [disableSetPassword, setDisableSetPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);



  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryResetCode = searchParams.get('resetcode');
    if(!queryResetCode){
      navigate('/404'); 
    }
  
    if (queryResetCode) {
      setResetcode(queryResetCode);
    }
  }, [location.search]);
  

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSetNewPassword = () => {
    if (password !== confirmPassword) {
      setHelperText('Passwords do not match');
      return;
    } 
      setPasswordsMatch(true);
      const passwordData = {
       resetcode,
       password,
    };
 Auth.resetPassword(passwordData, (response) => {
      if (response.status === 'success') {
        toast.success('Password Reset Successfully');      
        setTimeout(() => {
          navigate('/login');
          
        }, 500); 
      } else {
        toast.error('Password Reset Failed');
      }
    });
    
    
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setHelperText('');
    setDisableSetPassword(false);
  };



  return (
    <>
      <Helmet>
        <title>Set New Password | ML-Rank Tracker</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <img src={forgot} alt="Forgot Password" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              ML-Rank Tracker
            </Typography>

            <Typography variant="h7" >
              Reset Your Password
            </Typography>

            <TextField
      type={showPassword ? 'text' : 'password'}
      label="New Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      sx={{ mt: 3 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Iconify
              icon={showPassword ? 'feather:eye' : 'feather:eye-off'}
              onClick={handleTogglePasswordVisibility}
              style={{ cursor: 'pointer' }}
            />
          </InputAdornment>
        ),
      }}
    />

    <TextField
      type={showConfirmPassword ? 'text' : 'password'}
      label="Confirm Password"
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      sx={{ mt: 3 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Iconify
              icon={showConfirmPassword ? 'feather:eye' : 'feather:eye-off'}
              onClick={handleToggleConfirmPasswordVisibility}
              style={{ cursor: 'pointer' }}
            />
          </InputAdornment>
        ),
      }}
    />

            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={handleSetNewPassword}
              sx={{ mt: 3, mb: 2 }}
              disabled={disableSetPassword}
            >
              Set New Password
            </Button>

          </StyledContent>
        </Container>
      </StyledRoot>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
