import { Card, CardContent, Typography, Skeleton } from '@mui/material';
import React from 'react';
import { PieChart, Pie, Label, Legend, Tooltip } from 'recharts';
import keyword from 'src/module/keyword';

export default function AppCurrentVisits({ plan }) {
  const data01 = [
    { name: 'Used ', value: plan?.keyword - keyword?.getRemainingKeywords(), fill: '#e33232d9' },
    { name: 'Un-used', value: keyword?.getRemainingKeywords(), fill: '#3dab3dcf' },
  ];

  if (!plan || !plan.keyword) {
    return (
      <Card sx={{ marginTop: '105px' }}>
        <Skeleton variant="rectangular" width={500} height={295} />
      </Card>
    );
  }

  return (
    <Card sx={{ marginTop: '105px' }}>
      <CardContent>
        <Typography variant="h5">Keyword Consumption</Typography>
        <PieChart
          width={500}
          height={350}
          style={{
            marginTop: '-100px',
          }}
        >
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data01}
            cx={200}
            cy={200}
            outerRadius={80}
            label={(entry) => `${entry.name}: ${entry.value}`}
          />
          <Tooltip />
        </PieChart>
      </CardContent>
    </Card>
  );
}