import React, { useState, useContext } from 'react';
import { Button, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { MyContext } from 'src/pages/DashboardAppPage';
import downloadFile from '../../module/downloadCsv';
import Iconify from '../iconify';
import CollapsibleTable from '../collapse-table/collapse';

const DownloadButton = ({ fileId, countSize }) => {
  const { domainName } = useContext(MyContext);
  const [downloadSize, setDownloadSize] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const handleDownload = async () => {
    try {
      const response = await downloadFile(domainName.id, downloadSize);
      console.log(response);
      const decodedData = atob(response); // decode base64 data
      const csvData = new Blob([decodedData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `file_${domainName.id}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log('Download failed', error);
    }
  };

  const handlePopupOpen = () => {
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  const handlePopupSubmit = () => {
    handleDownload();
    handlePopupClose();
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    setDownloadSize(value);
  };

  const totalRows = CollapsibleTable();
  console.log(totalRows);

  const handleExportAll = async () => {
    try {
      const response = await downloadFile(domainName.id, countSize);
      console.log(response);
      const decodedData = atob(response); // decode base64 data
      const csvData = new Blob([decodedData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `file_${domainName.id}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log('Download failed', error);
    }
  };

  return (
    <div>
      <Tooltip title="Download All" placement="top">
        <Iconify
          icon="material-symbols:download-for-offline-rounded"
          width={25}
          height={25}
          sx={{
            cursor: 'pointer',
            color: '#2065d1',
          }}
          onClick={handlePopupOpen}
        />
      </Tooltip>
      <Dialog open={openPopup} onClose={handlePopupClose}>
        <DialogTitle>Enter Download Size</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="download-size"
            label="Number of rows"
            type="number"
            fullWidth
            value={downloadSize}
            onChange={handleSizeChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose}>Cancel</Button>
          <Button onClick={handleExportAll}>Export All</Button>
          <Button disabled={downloadSize == 0 ? true : false} onClick={handlePopupSubmit}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DownloadButton;
