import { Navigate } from 'react-router-dom';
import trackerApi from './trackerApi';

class Auth {
  // Authentication  Class //
  constructor() {
    this.user_token = JSON.parse(localStorage.getItem('Auth')) || {};
    this.isCd = false;
    this.sent = false;
  }

  setSent(value) {
    this.sent = value;
    return this.sent; // Return the updated value of sent
  }

  getSent() {
    return this.sent;
  }

  setIsCd(value) {
    this.isCd = value;
    return this.isCd; // Return the updated value of isCd
  }

  getIsCd() {
    return this.user_token.isCd;
  }

  isSubscribed() {
    return this.user_token.userDetails?.subscribed;
  }

  // Get Token //
  getToken() {
    return this.user_token.token;
  }

  // Get UserName //
  getUserName() {
    return this.user_token.userName;
  }

  // Get Login Id //
  getUserLoginId() {
    return this.user_token.id;
  }

  getVerified() {
    return this.user_token.verified;
  }

  getCustomerId() {
    return this.user_token.customerId;
  }

  // Get User's name //
  getName() {
    return this.user_token.name;
  }

  getIsFree() {
    return this.user_token.free;
  }

  // Get User Email //
  getEmail() {
    return this.user_token.email;
  }

  // For setting LocalStorage //
  setUserToken(newToken) {
    this.user_token = newToken;
    localStorage.setItem('Auth', JSON.stringify(newToken));
  }

  // For Removing localStorage //
  removeLoginDetails() {
    this.user_token.token = null;
    localStorage.removeItem('Auth');
    localStorage.removeItem('domainName');
    localStorage.removeItem('r_k');
  }

  // User For Login //
  authenticate = (loginData, callBack) => {
    this.removeLoginDetails();
    return trackerApi({
      method: 'POST',
      url: '/authenticate',
      data: loginData,
    })
      .then((response) => {
        this.setUserToken(response.data);
        callBack({ status: 'success' });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('Authentication failed', error);
      });
  };

  // For Login with Google-Gmail //
  authenticateGmail = (loginData, callBack) => {
    this.removeLoginDetails();
    return trackerApi({
      method: 'POST',
      url: '/authenticateGmail',
      data: loginData,
    })
      .then((response) => {
        this.setUserToken(response.data);
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Authentication by Gmail failed', error);
      });
  };

  registerUser = (userData, callBack) => {
    return trackerApi({
      method: 'POST',
      url: '/register',
      data: userData,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Authentication failed', error);
        callBack({ status: 'error' });
      });
  };

  verifyOTP = (otp, callBack) => {
    return trackerApi({
      method: 'POST',
      url: '/user/public/get-by-otp',
      data: otp,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Authentication failed', error);
        callBack({ status: 'error' });
      });
  };

  forgotPassword = (email, callBack) => {
    return trackerApi({
      method: 'POST',
      url: '/user/public/forgotPassword',
      data: { email },
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Authentication failed', error);
        callBack({ status: 'error' });
      });
  };

  resetPassword = (passwordData, callBack) => {
    return trackerApi({
      method: 'POST',
      url: '/user/public/resetPassword',
      data: passwordData,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('Failed to set the new password:', error);
        callBack({ status: 'error' });
      });
  };

  // For Logout //
  logout(callBack) {
    this.removeLoginDetails();
    callBack({ status: 'success' });
  }

  // For Checking User is Authenticated or not //
  isAuthenticated() {
    if (this.user_token !== undefined && this.user_token.token !== null && this.user_token.token !== undefined) {
      return true;
    }
    return false;
  }

  // Subscribe or not //
  // Subscribe or not //
  async subscribed() {
    try {
      const id = this.getUserLoginId(); // Get the user ID from the login credentials
      if (!id) {
        return false; // Return false if the user ID is not available
      }
      const response = await trackerApi({
        method: 'GET',
        url: `/subs-check/${id}`,
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log('Error checking subscription:', error);
      return false;
    }
  }

  // setSubscribed = async () => {
  //   const isSubscribed = await this.subscribed(this.getUserLoginId());
  //   this.setState({isSubscribed});
  // };

  // setState(newState) {
  //   this.state = { ...this.state, ...newState };
  // }
}

export default new Auth();
