import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button, Box, CircularProgress } from '@mui/material';
import { MyContext } from 'src/pages/DashboardAppPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import addCsvFile from 'src/module/csv';
import domain from 'src/module/domain';
import Auth from 'src/module/Auth';

const CsvUploader = ({ setUploadProgress, setOpen, setLoader, keywordData }) => {
  const [csvFile, setCsvFile] = useState(null);
  const { domainName, setKeywordData } = useContext(MyContext);
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (csvFile) {
      handleUpload();
    }
  }, [csvFile, setKeywordData]); // Add setKeywordData to the dependency array

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
    setOpen(false);
  };

  const handleUpload = async () => {
    if (!csvFile) {
      toast.error('Please select a file to upload');
      return;
    }

    setUploading(true);
    try {
      toast.info('Uploading file...');

      addCsvFile(domainName?.id, csvFile, (response) => {
        if (response.data === false) {
          toast.error('Something went wrong!');
        } else if (response.status === 'error') {
          toast.error('Something went wrong!');
        } else {
          toast.success('File uploaded successfully!');
        }
        // Call keywordData again
        domain.getByDomainId(domainName.id, 0, 30, 'Desktop', (response) => {
          if (response.status === 'success') {
            const data = response.data.keywordDetails;
            setKeywordData(data);
          } else {
            console.log('Error occurred while fetching data from the API.');
          }
        });
      });
    } catch (err) {
      toast.error('Failed to upload file');
    }

    setUploading(false);
    setLoading(false);
  };

  const uploadFile = (file, setUploadProgress) => {
    return new Promise((resolve, reject) => {
      const totalSize = file.size;
      let uploadedSize = 0;

      const progressInterval = setInterval(() => {
        if (uploadedSize >= totalSize) {
          clearInterval(progressInterval);
          resolve();
          return;
        }

        const progressIncrement = Math.floor(Math.random() * 10) + 1;
        uploadedSize += progressIncrement;

        const progressPercentage = Math.floor((uploadedSize / totalSize) * 100);

        setUploadProgress(progressPercentage);
        setLoader(true);
      }, 500);
    });
  };

  const handleButtonClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        variant="outlined"
        onClick={handleButtonClick}
        disabled={loading || uploading || Auth?.isSubscribed()}
        startIcon={uploading ? <CircularProgress size={20} /> : null}
      >
        {loading ? 'Uploading...' : 'Upload CSV'}
      </Button>
      <input
        id="file-upload"
        type="file"
        accept=".csv"
        multiple
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <ToastContainer autoClose={3000} />
    </Box>
  );
};

export default CsvUploader;
