import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import user from 'src/module/user';
import { ToastContainer, toast } from 'react-toastify';
import Auth from 'src/module/Auth';
import { InputAdornment } from '@mui/material';
import Iconify from '../iconify';

const UpdateProfileDialog = ({ open, handleClose }) => {
  const [password, setPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [error, setError] = useState(false);
  //   React.useEffect(() => {}, [handleClose]);

  const handleSave = () => {
    const data = {
      oldPassword,
      newPassword: password,
    };
    user.changePassword(Auth?.getUserLoginId(), data, (response) => {
      if (response.data == 'Wrong Password Provided') {
        toast.error('Wrong Old Password');
      } else {
        toast.success('Changed Successfully');
        handleClose();
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
      }
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleCheckConfirmPassword = () => {
    if (password !== confirmPassword) {
      setHelperText('Passwords do not match');
      setError(true);
    }
  };
  const handleConfirmPassword = (e) => {
    setError(false);
    setConfirmPassword(e.target.value);
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Profile</DialogTitle>
        <DialogContent sx={{ m: 2, p: 3 }}>
          <TextField
            sx={{ my: 1 }}
            label="Old Password"
            fullWidth
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            value={password}
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mt: 3 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify
                    icon={showPassword ? 'feather:eye' : 'feather:eye-off'}
                    onClick={handleTogglePasswordVisibility}
                    style={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password"
            value={confirmPassword}
            fullWidth
            error={error}
            onChange={handleConfirmPassword}
            sx={{ mt: 3 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify
                    icon={showConfirmPassword ? 'feather:eye' : 'feather:eye-off'}
                    onClick={handleToggleConfirmPasswordVisibility}
                    style={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
            onBlur={handleCheckConfirmPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default UpdateProfileDialog;
