import trackerApi from './trackerApi';

class Region {
  // Region  Class //
  constructor() {
    this.region = JSON.parse(localStorage.getItem('Regions')) || {};
  }

  // For setting LocalStorage //
  setRegion(newToken) {
    this.region = newToken;
    localStorage.setItem('Regions', JSON.stringify(newToken));
  }

  // For Removing localStorage //
  removeRegionDetails() {
    this.region = null;
    localStorage.removeItem('Regions');
  }

  // Get Regions //
  getRegions = () => {
    return trackerApi({
      method: 'GET',
      url: '/region/',
    })
      .then((response) => {
        this.setRegion(response.data);
      })
      .catch((error) => {
        console.log('Authentication failed', error);
      });
  };
}
export default new Region();
