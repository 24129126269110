import trackerApi from './trackerApi';

const downloadFile = async (id, size) => {
  try {
    const response = await trackerApi({
      method: 'GET',
      url: `/keyword/export-csv/${id}/${size}`,
      responseType: 'text',
    });
    
    const isDownloadSuccessful = response.data;
    if (isDownloadSuccessful) {
      const fileName = `file_${Math.floor(Math.random() * 1000000)}.csv`;
      const decodedData = atob(response.data);
      const csvData = new Blob([decodedData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log('Download failed.');
    }
  } catch (error) {
    console.log('Download failed.', error);
  }
};

export default downloadFile;
