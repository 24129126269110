import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField, FormControlLabel, Checkbox, Grid, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import Auth from '../../../module/Auth';
import user from '../../../module/user';

export default function LoginForm(props) {
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState('');
  const [helperTextPassword, setHelperTextPassword] = useState('');
  const [helperTextConfirmPassword, setHelperTextConfirmPassword] = useState('');
  const [helperTextName, setHelperTextName] = useState('');
  const [helperText, setHelperText] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  // const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(false);
  const blockedEmailExtensions = [
    'gmail.com',
    'yahoo.com',
    'emailboxa.online',
    'outlook.com',
    'aol.com',
    'protonmail.com',
    'tutanota.com',
    'zoho.com',
    'mail.com',
    'gmx.com',
    'yandex.com',
    'icloud.com',
    'fastmail.com',
    'hushmail.com',
    'guerrillamail.com',
    'inbox.com',
    'lycos.com',
    'mailfence.com',
    'runbox.com',
    'msn.com',
    'hotmail.com',
    'live.com',
    '.pk',
    'pakistanphilately.com',
    'chodyi.com',
    'icznn.com',
    'clout.wiki',
    'tutuapp.bid',
    'hexi.pics',
    'finews.biz',
    'afia.pro',
    'lyft.live',
    'socam.me',
    'tempmailid.org',
    'tempmailid.net',
    'uspmail.com',
    'dyyar.com',
    'techdf.com',
    'omeea.com',
    'vistaemail.com',
    'yesmail.edu.pl',
    'fixedfor.com',
    'gufum.com',
    'businesshowtobooks.com',
    'getresearchpower.com',
    'cokhiotosongiang.com',
    'liberiaom.com',
    'weblivein.com',
    'rontgateprop.com',
    'corpkind.com',
    'yopmail.com',
    '98usd.com',
    'bryantspoint.com',
    'ybananaulx.com',
    'sml2020.xyz',
    'tinorecords.com',
    'yourssecuremail.com',
    'dadosa.xyz',
    'rabihtech.xyz',
    'cazlp.com',
    'zslsz.com',
    'avidapro.com',
    'madeforthat.org',
    'lettrs.email',
    'ai1.lol',
    'chronicle.digital',
    'uf.edu.pl',
    '10mail.org',
    'flymail.tk',
    'spymail.one',
    'emltmp.com',
    'emlhub.com',
    '10mail.tk',
    'spymail.one',
    'emlpro.com',
    'yomail.info',
    'dropmail.me',
    'iubridge.com',
    'jocdk.com',
    'ezztt.com',
    'laafd.com',
    'vjuum.com',
    'roanaq.com',
    'txcct.com',
  ];

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      confirmPassword: confirmPasswordRef.current.value,
      name: nameRef.current.value,
      free: props.url ? true : false,
    };

    if (data.email === '' || data.password === '' || data.confirmPassword === '' || data.name === '') {
      setHelperTextEmail('Email is required');
      setHelperTextPassword('Password is required');
      setHelperTextConfirmPassword('Confirm Password is required');
      setHelperTextName('Name is required');
      // setHelperTextConfirmPassword('Password and Confirm Password must be the same');
    } else {
      handleSignup(data);
    }
  };

  const handleCheckConfirmPassword = (value) => {
    if (value !== passwordRef.current.value) {
      setConfirmPasswordError(true);
    }
  };
  // const handleSignup = (data) => {
  //   if (checked) {
  //     const domain = data.email.split('@')[1].toLowerCase();
  //     if (blockedEmailExtensions.includes(domain)) {
  //       setErrorMessage('Business email is required for free account');
  //     } else {
  //       Auth.registerUser(data, (response) => {
  //         if (response.status === 'success') {
  //           toast.success('Registered Successfully');
  //           navigate('/login');
  //         } else {
  //           toast.error('Failed to Register');
  //         }
  //       });
  //     }
  //   } else {
  //     Auth.registerUser(data, (response) => {
  //       if (response.status === 'success') {
  //         toast.success('Registered Successfully');
  //         navigate('/login');
  //       } else {
  //         toast.error('Failed to Register');
  //       }
  //     });
  //   }
  // };

  const handleSignup = (data) => {
    const domain = data.email.split('@')[1].toLowerCase();
    if (data.free && blockedEmailExtensions.includes(domain)) {
      setErrorMessage('Business email is required for free account');
    } else {
      Auth.registerUser(data, (response) => {
        if (response.status === 'success') {
          toast.success('Registered Successfully');
          navigate('/login');
        } else {
          toast.error('Failed to Register');
        }
      });
    }
  };
  const checkEmail = () => {
    user.checkEmail(emailRef.current.value, (response) => {
      if (response.status === 'success') {
        if (response.data === false) {
          setHelperTextEmail('Email Already Exists');
          setError(true);
        } else {
          setHelperTextEmail('');
        }
      }
    });
  };

  const handleConfirmPasswordChange = (e) => {
    if (confirmPasswordError) {
      setConfirmPasswordError(false);
    }
  };

  // const hanldeCheckBox = () => {
  //   setChecked(!checked);
  // };

  const handleEmailError = () => {
    setHelperText(false);
    setErrorMessage(null);
    setError(false);
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          <TextField
            error={helperTextEmail || errorMessage !== null}
            helperText={helperTextEmail || errorMessage}
            onFocus={() => setHelperTextEmail(false)}
            onBlur={checkEmail}
            onChange={handleEmailError}
            name="email"
            type="email"
            label={props.url ? 'Business Email *' : 'Email *'}
            inputRef={emailRef}
            sx={{ color: 'red' }}
          />

          <TextField
            error={helperTextPassword}
            helperText={helperTextPassword}
            onChange={() => setHelperTextPassword(false)}
            name="password"
            label="Password *"
            inputRef={passwordRef}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="confirmPassword"
            label="Confirm Password *"
            inputRef={confirmPasswordRef}
            onBlur={(e) => handleCheckConfirmPassword(e.target.value)}
            onChange={handleConfirmPasswordChange}
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={confirmPasswordError || helperTextConfirmPassword}
            helperText={
              confirmPasswordError ? 'Password and Confirm Password must be the same' : helperTextConfirmPassword
            }
            // onChange={() => setHelperTextConfirmPassword(false)}
          />
          <TextField
            error={helperTextName}
            helperText={helperTextName}
            onChange={() => setHelperTextName(false)}
            inputRef={nameRef}
            name="name"
            type="text"
            label="Name *"
          />
        </Stack>
        {/* <Grid width={'200px'}>
          <FormControlLabel control={<Checkbox onClick={hanldeCheckBox} />} label="Basic Free Pack" />{' '}
          <Tooltip
            placement="right-start"
            title="Limit of 100 keywords and two domains are included in the basic plan."
          >
            <IconButton
              sx={{
                marginLeft: '-25px',
              }}
              edge="start"
            >
              <Iconify icon={'ph:question'} />
            </IconButton>
          </Tooltip>
        </Grid> */}
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={error}>
            Sign Up
          </LoadingButton>
        </Stack>
      </form>

      <ToastContainer />
    </>
  );
}
