// routes
import { GoogleOAuthProvider } from '@react-oauth/google';

// theme
import ThemeProvider from './theme';
// components

import Router from './routes';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <GoogleOAuthProvider clientId="974435598285-gcunkeet228lkt7vngt6kqqadtdfma6q.apps.googleusercontent.com">
      <ThemeProvider>
        {/* <ScrollToTop />
        <StyledChart /> */}
        <Router />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
