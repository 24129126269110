import trackerApi from './trackerApi';

class User {
  // User  Class //

  // Check Email //
  checkEmail = (email, callBack) => {
    return trackerApi({
      method: 'GET',
      url: `/user/check-email/${email}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('failed', error);
        callBack({ status: 'error', data: error });
      });
  };

  resendEmail = (id, callBack) => {
    return trackerApi({
      method: 'GET',
      url: `/user/resend-email/${id}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('failed', error);
        callBack({ status: 'error', data: error });
      });
  };

  // update user //

  updateUser = (id, userData, callBack) => {
    return trackerApi({
      method: 'PUT',
      url: `/user/${id}`,
      data: userData,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('failed', error);
        callBack({ status: 'error', data: error });
      });
  };

  changePassword = (id, userData, callBack) => {
    return trackerApi({
      method: 'PUT',
      url: `/user/change-password/${id}`,
      data: userData,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('failed', error);
        callBack({ status: 'error', data: error });
      });
  };
}
export default new User();
