

const config = {
    development: {
      apiUrl: 'https://dev-ranktracker-api-dfy6gslhza-uc.a.run.app/ml-tracker',
    },
    production: {
      apiUrl: 'https://ranktrackerbe-dfy6gslhza-uc.a.run.app/ml-tracker',
    },
  };
  
  export default config;
  